// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-careers-template-js": () => import("./../../../src/templates/careers-template.js" /* webpackChunkName: "component---src-templates-careers-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-events-template-js": () => import("./../../../src/templates/events-template.js" /* webpackChunkName: "component---src-templates-events-template-js" */),
  "component---src-templates-history-template-js": () => import("./../../../src/templates/history-template.js" /* webpackChunkName: "component---src-templates-history-template-js" */),
  "component---src-templates-homepage-template-js": () => import("./../../../src/templates/homepage-template.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-institute-template-js": () => import("./../../../src/templates/institute-template.js" /* webpackChunkName: "component---src-templates-institute-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-promo-template-js": () => import("./../../../src/templates/promo-template.js" /* webpackChunkName: "component---src-templates-promo-template-js" */),
  "component---src-templates-resources-template-js": () => import("./../../../src/templates/resources-template.js" /* webpackChunkName: "component---src-templates-resources-template-js" */),
  "component---src-templates-search-template-js": () => import("./../../../src/templates/search-template.js" /* webpackChunkName: "component---src-templates-search-template-js" */),
  "component---src-templates-services-template-js": () => import("./../../../src/templates/services-template.js" /* webpackChunkName: "component---src-templates-services-template-js" */),
  "component---src-templates-simple-content-template-js": () => import("./../../../src/templates/simple-content-template.js" /* webpackChunkName: "component---src-templates-simple-content-template-js" */),
  "component---src-templates-used-equipment-template-js": () => import("./../../../src/templates/used-equipment-template.js" /* webpackChunkName: "component---src-templates-used-equipment-template-js" */)
}

